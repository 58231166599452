<template>
  <div
    v-if="items && items.length > 0"
    class="col-6"
  >
    <div class="card card--profile-tasks">
      <div
        v-b-toggle="`collapse-${type}`"
        class="card-header"
      >
        <h3 class="float-start">
          <a data-action="collapse"><i
            :data-feather="isIncorporation ? 'sunrise' : 'sunset'"
            class="w-icon-24"
          /> Future {{ type }} <span
            v-if="!loading"
            class="badge bg-light-info"
          >{{ items.length }}</span></a>
        </h3>
        <div class="heading-elements">
          <ul class="list-inline mb-0 disp-in ms-2">
            <li>
              <a data-action="collapse"><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              ><polyline points="6 9 12 15 18 9" /></svg></a>
            </li>
          </ul>
        </div>
      </div>
      <b-collapse
        :id="`collapse-${type}`"
        :visible="fields.find(e => e.name.toLowerCase() == type).visibility"
      >
        <div class="card-body">
          <hr>
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="table-responsive"
          >
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Photo</th>
                  <th>Name</th>
                  <th>Host</th>
                  <th>{{ isIncorporation ? 'Starting' : 'Retirement' }} date</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td v-html="item.img_table" />
                  <td>{{ item.name }}</td>
                  <td>{{ item.hosts.map(el => el.name).join(', ') }}</td>
                  <td>{{ isIncorporation ? item.researcher_start_date : item.end_at_table }}</td>
                  <td class="text-end">
                    <b-dropdown
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      right
                    >
                      <template #button-content>
                        <i data-feather="more-vertical" />
                      </template>
                      <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                        <b-dropdown-item
                          :href="$router.resolve({ name: 'researcher.administrative-data.researcher', params: { id: item.id }, query: { actAs: $route.query.actAs } }).href"
                          target="_blank"
                        >
                          <i
                            class="me-50"
                            data-feather="eye"
                          /> Go to admin data
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCollapse,
  },
  props: {
    type: { type: String, required: true },
  },
  data() {
    return {
      userId: this.$route.params.id,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      incorporations: 'users/incorporations',
      retirements: 'users/retirements',
      loggedUser: 'auth/admin',
      user: 'users/user',
      fields: 'modals/tableSettingsFields',
    }),
    isIncorporation() {
      return this.type === 'incorporations'
    },
    items() {
      return this.isIncorporation ? this.incorporations : this.retirements
    },
  },
  async mounted() {
    this.reloadPage()
  },
  methods: {
    async reloadPage() {
      this.loading = true
      await this.$store.dispatch(`users/fetch${this.isIncorporation ? 'Incorporations' : 'Retirements'}`)
      this.loading = false
      // eslint-disable-next-line no-undef
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },
}
</script>

<style>

</style>
