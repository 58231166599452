<template>
  <!-- start calls -->
  <div class="row">
    <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div class="call__stats">
        <h4 class="text-center">
          People
        </h4>
        <div class="row d-flex justify-content-between">
          <div class="col-auto text-center d-flex flex-column">
            <p class="my-0">
              <span class="text-info display-4">{{ convo.researchers.length }}</span>
            </p>
            <p class="my-0">
              Researchers
            </p>
          </div>
          <div class="col-auto text-center d-flex flex-column">
            <p class="my-0">
              <span class="text-info display-4">{{ convo.peers }}</span>
            </p>
            <p class="my-0">
              Peers
            </p>
          </div>
          <div class="col-auto text-center d-flex flex-column">
            <p class="my-0">
              <span class="text-info display-4">{{ convo.hosts }}</span>
            </p>
            <p class="my-0">
              Hosts
            </p>
          </div>
          <div class="col-auto text-center d-flex flex-column">
            <p class="my-0">
              <span class="text-info display-4">{{ convo.pcms }}</span>
            </p>
            <p class="my-0">
              PCMembers
            </p>
          </div>

        </div>
      </div>
    </div>
    <div class="col-xxl-6 col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div class="call__stats">
        <h4 class="text-center">
          Applicants by area
        </h4>
        <div class="row d-flex justify-content-between">
          <div
            v-for="area in convo.areas.filter(e => !e.parent_id)"
            :key="area.id"
            class="col-auto text-center d-flex flex-column"
          >
            <p class="my-0">
              <span class="text-info display-4">{{ convo.researchers.filter(e => e.areas.some(r => r.id === area.id)).length }}</span>
            </p>
            <p class="my-0">
              {{ area.code }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-2 col-xl-4 col-lg-6 col-md-6 col-sm-6">
      <div class="call__stats">
        <h4 class="text-center">
          Researchers genre
        </h4>
        <div class="row">
          <div class="col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-info display-4">{{ males }}</span>
            </p>
            <p class="my-0">
              Man
            </p>
          </div>
          <div class="col-6 col-6 text-center d-flex flex-column justify-content-center">
            <p class="my-0">
              <span class="text-info display-4">{{ females }}</span>
            </p>
            <p class="my-0">
              Woman
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end calls -->
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Paginator from '@/views/back/partials/Paginator.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    Paginator,
  },
  props: {
    convo: {
      type: Object,
      required: true,
    },
    promotion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
    males() {
      return this.convo.researchers.filter(e => e.genre_id == 1).length
    },
    females() {
      return this.convo.researchers.filter(e => e.genre_id == 2).length
    },
  },
  async mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    setLoading(loading) {
      this.loading = loading
    },
  },
}
</script>
