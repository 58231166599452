<template>
  <div
    class="app-content content "
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <div class="avatar avatar-custom avatar-xl float-start">
                <img
                  :src="icon_path ? icon_path : require('@/assets/images/avatars/user_default.png')"
                  alt="avatar"
                  class="img-fluid"
                >
              </div>
              <h2 class="content-header-title  mb-0">
                {{ user.name }}
                <span>{{ user.areas ? `${user.areas.name}` : '' }}</span>
                <template v-if="user.current_institution">
                  <span>{{ user.current_institution.code }}</span>
                  <!-- <span>{{ user.areas && user.areas[0] ? user.areas[0].code : '' }}</span> -->
                  <span>{{ user.current_institution.pivot && user.current_institution.pivot.department_aux ? user.current_institution.pivot.department_aux.name : '' }}</span>
                </template>
              </h2>
            </div>
          </div>

        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div
              class="btn-group"
            >
              <b-dropdown
                variant="button"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <button
                    id="dropdownMenuButton"
                    class="btn btn-primary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Quick actions <i data-feather="chevron-down" />
                  </button>
                </template>
                <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                  <template v-if="loggedUser.roles.includes('super-admin')">
                    <h6 class="dropdown-header">
                      Admin
                    </h6>
                    <b-dropdown-item @click="openFileInput">
                      <a
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasEditPhoto"
                        aria-controls="offcanvasEditPhoto"
                      >Edit photo</a>
                    </b-dropdown-item>
                    <div class="dropdown-divider" />
                  </template>
                  <b-dropdown-item @click="$router.push({name: 'researcher.personal-data.index'})">
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#editCV"
                      aria-controls="editCV"
                    >Edit CV</a>
                  </b-dropdown-item>
                  <b-dropdown-item @click="$router.push({name: 'researcher.scientific-production-report.index'})">
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#scientficProductionReport"
                      aria-controls="scientficProductionReport"
                    >Scientific Production Report</a>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="user.CVFile.length > 0 && !hide"
                    @click="viewCV"
                  >
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#abridgedCV"
                      aria-controls="abridgedCV"
                    >View abridged CV</a>
                  </b-dropdown-item>
                  <div class="dropdown-divider" />
                  <b-dropdown-item @click="$router.push({name: 'researcher.edit-access'})">
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#editCV"
                      aria-controls="editCV"
                    >Edit intranet access</a>
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </div>
            <span
              class="dis-ib"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Table settings"
            >
              <a
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                @click="openTableSettings()"
              >
                <i data-feather="settings" />
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <template
                v-for="field, index in fields"
              >
                <div
                  v-if="field.name === 'Promotion' || field.name === 'Call'"
                  :key="index"
                >
                  <template v-for="convo in convoData">
                    <CallActive
                      v-if="field.checked && (field.name === 'Promotion' && convo && convo.promotion || field.name === 'Call' && convo && !convo.promotion)"
                      :key="convo.id"
                      :convo="convo"
                      :promotion="convo.promotion"
                    />
                  </template>
                </div>
                <GeneralNotifications
                  v-if="field.checked && field.name === 'General notifications'"
                  :key="index"
                  @showAlert="showAlert"
                />
                <OtherNotifications
                  v-if="field.checked && field.name === 'Other notifications'"
                  :key="index"
                  @showAlert="showAlert"
                />
                <PendingAuthNotifications
                  v-if="field.checked && field.name === 'Notifications pending of authorization'"
                  :key="index"
                  @showAlert="showAlert"
                />
                <OSHNotifications
                  v-if="field.checked && field.name === 'OSH notifications'"
                  :key="index"
                  @showAlert="showAlert"
                />
                <CalendarAndAlarms
                  v-if="field.checked && field.name === 'Calendar'"
                  :key="index"
                  @showAlert="showAlert"
                />
                <Tasks
                  v-if="field.checked && field.name === 'Tasks'"
                  :key="index"
                />
                <Notifications
                  v-if="!hide"
                  :key="index"
                />
                <Future
                  v-if="field.checked && field.name === 'Incorporations'"
                  :key="index"
                  :type="'incorporations'"
                />
                <Future
                  v-if="field.checked && field.name === 'Retirements'"
                  :key="index"
                  :type="'retirements'"
                />
                <TemporaryLeaves
                  v-if="field.checked && field.name === 'Temporary leaves'"
                  :key="index"
                />
                <UnpaidLeaves
                  v-if="field.checked && field.name === 'Unpaid leaves'"
                  :key="index"
                />
                <Trips
                  v-if="field.checked && field.name === 'Trips'"
                  :key="index"
                />
                <FutureTrips
                  v-if="field.checked && field.name === 'Trips' && !loggedUser.roles.includes('collaborator')"
                  :key="index"
                />
                <Traces
                  v-if="field.checked && field.name === 'Traces'"
                  :key="index"
                />
              </template>
            </div>
          </div>
          <ItemInfoSheet>
            <template #info-sheet-title>
              Read notification
            </template>
            <template #info-sheet>
              <div class="offcanvas-body">
                <div class="mb-1 offcanvas-body--view--title">
                  <p>Send<br>
                    <strong>{{ active.date }}</strong></p>
                </div>
                <hr>
                <div class="mb-1 offcanvas-body--view--title">
                  <p>Subject<br>
                    <strong>{{ active.title }}</strong></p>
                </div>
                <div class="mb-1">
                  <p>Message<br>
                    <strong v-html="active.message" />
                  </p>
                </div>
                <div
                  v-if="active.files && active.files.length > 0"
                  class="mb-1"
                >
                  <p>Attached file(s)<br>
                    <template v-for="file in active.files">
                      <a
                        :key="file.id"
                        :href="file.url"
                        title="open file"
                        target="_blank"
                      >
                        <i data-feather="eye" /> {{ file.name }}
                      </a>
                      <br>
                    </template>
                  </p>
                </div>
              </div>
              <div class="offcanvas-footer mt-auto">
                <a
                  title="apply actions"
                  class="btn btn-dark mb-1 d-grid w-100"
                  @click="$store.dispatch('modals/toggleInfoItemSheet', false)"
                >Close</a>
              </div>
            </template>
          </ItemInfoSheet>

          <TableColumns :show-visibility="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import CalendarAndAlarms from '@/views/back/researcher/dashboard/components/CalendarAndAlarms.vue'
import Tasks from '@/views/back/researcher/dashboard/components/Tasks.vue'
import Notifications from '@/views/back/researcher/dashboard/components/Notifications.vue'
import Trips from '@/views/back/researcher/dashboard/components/Trips.vue'
import FutureTrips from '@/views/back/researcher/dashboard/components/FutureTrips.vue'
import Vue from 'vue'
import Traces from './components/Traces.vue'
import UnpaidLeaves from './components/UnpaidLeaves.vue'
import TemporaryLeaves from './components/TemporaryLeaves.vue'
import Future from './components/Future.vue'
import CallActive from './components/CallActive.vue'
import GeneralNotifications from './components/GeneralNotifications.vue'
import OtherNotifications from './components/OtherNotifications.vue'
import OSHNotifications from './components/OSHNotifications.vue'
import PendingAuthNotifications from './components/PendingAuthNotifications.vue'
import TableColumns from '../../partials/offcanvas/TableColumns.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    CalendarAndAlarms,
    Traces,
    Tasks,
    Notifications,
    Trips,
    ItemInfoSheet,
    UnpaidLeaves,
    TemporaryLeaves,
    Future,
    CallActive,
    TableColumns,
    GeneralNotifications,
    OtherNotifications,
    PendingAuthNotifications,
    OSHNotifications,
    FutureTrips,
  },
  data() {
    return {
      userId: this.$route.params.id,
      openEdit: false,
      loading: true,
      yearBetween: {},
      icon_path: '',
      hide: true,
      active: {},
      convoData: {},
      defaultFields: [
        {
          name: 'General notifications',
          checked: true,
          order: 1,
        },
        {
          name: 'Notifications pending of authorization',
          checked: true,
          order: 2,
        },
        {
          name: 'Other notifications',
          checked: true,
          order: 3,
        },
        {
          name: 'Call',
          checked: true,
          order: 4,
        },
        {
          name: 'Promotion',
          checked: true,
          order: 5,
        },
        {
          name: 'OSH notifications',
          checked: true,
          order: 6,
        },
        {
          name: 'Calendar',
          checked: true,
          order: 7,
          visibility: true,
        },
        {
          name: 'Tasks',
          checked: true,
          order: 7,
        },
        {
          name: 'Incorporations',
          checked: true,
          order: 8,
        },
        {
          name: 'Retirements',
          checked: true,
          order: 9,
        },
        {
          name: 'Temporary leaves',
          checked: true,
          order: 10,
        },
        {
          name: 'Unpaid leaves',
          checked: true,
          order: 11,
        },
        {
          name: 'Trips',
          checked: true,
          order: 12,
        },
        {
          name: 'Traces',
          checked: true,
          order: 12,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      // user: 'auth/researcher',
      user: 'users/user',
      loggedUser: 'auth/admin',
      fields: 'modals/tableSettingsFields',
    }),
  },
  async mounted() {
    await this.reloadDashboard()

    if (this.user.enter_convo_id) {
      await this.$store.dispatch('convo/fetchSpecific', this.user.enter_convo_id)
    }

    await this.$store.dispatch('modals/fetchUserFields', 'dashboard_admin')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'dashboard_admin',
      })
    }

    Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/get-active-calls`).then(resp => {
      this.convoData = resp.data.data
    })
  },
  methods: {
    viewCV() {
      if (this.user.CVFile.length > 0) {
        window.open(this.user.CVFile.length > 0 ? this.user.CVFile[0].url : null)
      }
    },
    openTableSettings() {
      this.$store.dispatch('modals/toggleTableSettings', true)
    },
    showEvaluation() {
      // WIP until we import the senior calls
      // if (this.user.enter_convo_id) {
      //   this.$store.dispatch('modals/toggleFormData', true)
      // }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.$toastr.success('', 'Copied to clipboard')
    },
    openFileInput() {
      this.$refs.fileInput.click()
    },
    async handleFileChange(event) {
      const file = event.target.files[0]
      if (file || this.scientificPersonalInfo.avatar.length > 0) {
        this.loading = true
        if (file) {
          file.weight = (file.size / 1024 / 1024).toFixed(2)
          const newAvatar = {
            name: file.name,
            original_name: file.name,
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            file,
          }
          const userAvatar = {
            avatar: newAvatar,
            user_id: this.user.id,
          }
          await this.$store.dispatch('users/uploadAvatar', userAvatar)
          this.newAvatar = []
          this.reloadDashboard()
        }
      }
    },
    async reloadDashboard() {
      if (this.userId && this.loggedUser.roles.includes('super-admin')) {
        await this.$store.dispatch('users/fetchUser', this.userId)
      } else if (this.$route.query.prefilterUser && this.loggedUser.roles.includes('super-admin')) {
        await this.$store.dispatch('users/fetchUser', this.$route.query.prefilterUser)
      } else {
        await this.$store.dispatch('users/fetchUser', this.loggedUser.id)
      }

      this.icon_path = this.user ? this.user.avatarUrl : ''

      if (Array.isArray(this.user.areas)) {
        [this.user.areas] = this.user.areas
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    showAlert(alert) {
      this.active = alert
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
    },
  },
}
</script>
