<template>
  <!-- start calls -->
  <div
    v-if="convo.id"
    class="card card--profile-tasks"
  >
    <div
      v-b-toggle="`collapseCallActive-${convo.id}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse"><i
          data-feather="message-square"
          class="w-icon-24"
        /> {{ convo.title }} <small><span class="badge bg-light-info">{{ convo.current_access ? convo.current_access.name : '-' }}</span></small></a>
      </h3>
      <div class="heading-elements">
        <span
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Go to Call"
        >
          <a
            :href="$router.resolve({ name: `admin.calls.${convo.promotion ? 'promotion' : (convo.senior ? 'senior' : 'academy')}.view`, params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
            target="_blank"
            class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
          ><i data-feather="eye" /></a>
        </span>
        <span
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Edit Call"
        ><a
          :href="$router.resolve({ name: `admin.calls.${convo.promotion ? 'promotion' : (convo.senior ? 'senior' : 'academy')}.edit`, params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
          target="_blank"
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
        ><i data-feather="edit-2" /></a></span>
        <ul class="list-inline mb-0 disp-in ms-2">
          <li>
            <a data-action="collapse"><svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down"
            ><polyline points="6 9 12 15 18 9" /></svg></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseCallActive-${convo.id}`"
      :visible="fields.find(e => e.name == 'Call' || e.name == 'Promotion').visibility"
    >
      <div class="card-content collapse show">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <a
                :href="$router.resolve({ name: `admin.calls.${convo.promotion ? 'promotion' : (convo.senior ? 'senior' : 'academy')}.view`, params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
                target="_blank"
                class="btn btn-dark btn-sm me-1"
              >View applicants <i data-feather="arrow-up-right" /></a>
              <a
                :href="$router.resolve({ name: `admin.calls.${convo.promotion ? 'promotion' : (convo.senior ? 'senior' : 'academy')}.groups`, params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
                target="_blank"
                class="btn btn-dark btn-sm"
              >View evaluators <i data-feather="arrow-up-right" /></a>
            </div>
          </div>
          <hr>
          <h5>Stats</h5>
          <CallStats
            v-if="!promotion"
            :convo="convo"
          />
          <PromotionStats
            v-if="promotion"
            :convo="convo"
          />
          <hr>
          <h5>Call dates</h5>
          <div class="row">
            <div class="col-auto">
              <p><small>Call open access</small><br><strong>{{ convo.start_date }}</strong></p>
            </div>
            <div class="col-auto">
              <p><small>Call closed access</small><br><strong>{{ convo.end_date }}</strong></p>
            </div>
            <div class="col-auto">
              <p><small>Day of the meeting for the final act</small><br><strong>{{ convo.meeting_day }}</strong></p>
            </div>
          </div>
          <hr>
          <h5>Acces to users</h5>
          <div class="row">
            <div
              v-for="access in convo.access"
              :key="access.id"
              class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
            >
              <p><small>{{ access.name }}</small><br><strong>From {{ access.start_date }} <br>to {{ access.end_date }} </strong></p>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
  <!-- end calls -->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import CallStats from './CallStats.vue'
import PromotionStats from './PromotionStats.vue'

export default {
  components: {
    BCollapse,
    CallStats,
    PromotionStats,
  },
  props: {
    convo: {
      type: Object,
      required: true,
    },
    promotion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      fields: 'modals/tableSettingsFields',
    }),
    elegibles() {
      return this.convo.applicants.filter(e => e.convo_status.some(r => r.convo_id === this.convo.id && r.status !== 3)).length
    },
    noElegibles() {
      return this.convo.applicants.filter(e => e.convo_status.some(r => r.convo_id === this.convo.id && r.status === 3)).length
    },
    males() {
      return this.convo.applicants.filter(e => e.genre_id === 1).length
    },
    females() {
      return this.convo.applicants.filter(e => e.genre_id === 2).length
    },
    spanish() {
      return this.convo.applicants.filter(e => e.citizenship_id_1 === 21 || e.citizenship_id_1 === 808).length
    },
    others() {
      return this.convo.applicants.filter(e => e.citizenship_id_1 !== 21 && e.citizenship_id_1 !== 808).length
    },
  },
  async mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    setLoading(loading) {
      this.loading = loading
    },
  },
}
</script>
